import React, { useState } from "react";
import { DGSButton } from "../DGSButton";
import { telegram } from "../../api/telegram";

export const FormRequest = ({ full = true, my = true }) => {
  const formControlClasses =
    "w-full px-6 py-2 text-gray-700 rounded-md border border-solid border-gray-300 focus:border-gray-400 focus:outline-none";
  const controlErrorClasses =
    "absolute top-11 left-5 lg:left-7 text-xs text-red-600";
  const [name, setName] = useState("");
  const [nameDirty, setNameDirty] = useState(false);
  const [nameError, setNameError] = useState(true);
  const [phone, setPhone] = useState("");
  const [phoneDirty, setPhoneDirty] = useState(false);
  const [phoneError, setPhoneError] = useState(true);

  const blurHandler = (e) => {
    switch (e.target.name) {
      case "name":
        setNameDirty(true);
        break;
      case "phone":
        setPhoneDirty(true);
        break;
      default:
    }
  };
  const nameHandler = (e) => {
    e.target.value.trim().length > 0 ? setNameError(false) : setNameError(true);
    setName(e.target.value);
  };
  const phoneHandler = (e) => {
    const re = /^[\d\+][\d\(\)\ -]{8,18}\d$/; //eslint-disable-line
    re.test(String(e.target.value).toLocaleLowerCase())
      ? setPhoneError(false)
      : setPhoneError(true);

    setPhone(e.target.value);
  };
  const formClean = () => {
    setName("");
    setNameDirty(false);
    setNameError(true);
    setPhone("");
    setPhoneDirty(false);
    setPhoneError(true);
  };

  const buttonClicked = () => {
    if (!nameError && !phoneError) {
      //Отправка формы
      let message = `<strong>Сообщение с сайта DGS:</strong>\nИмя: ${name}\nТелефон: ${phone}`;
      // console.log("Имя: " + name, "Телефон: " + phone, Date());
      telegram(message);
      formClean();
    } else {
      setPhoneDirty(true);
      setNameDirty(true);
    }
  };

  return (
    <>
      {/* <h2 className="flex pt-16 justify-center">Связаться с нами</h2> */}
      <form
        className={`flex flex-col md:flex-row gap-6 md:gap-6 py-8 ${
          my && "my-6"
        } ${
          full
            ? // ? "w-full px-5 md:px-10 lg:px-20"
              "w-full px-6"
            : "sm:mx-5 md:mx-10 lg:mx-20 px-6 sm:rounded-sm"
        } items-center justify-normal bg-neutral-100`}
      >
        <div className="w-full relative">
          <input
            value={name}
            name="name"
            className={formControlClasses}
            placeholder="Ваше имя*"
            onBlur={(e) => blurHandler(e)}
            onChange={(e) => nameHandler(e)}
          ></input>
          {nameDirty && nameError && (
            <div className={controlErrorClasses}>Просьба заполнить имя</div>
          )}
        </div>
        <div className="w-full relative">
          <input
            value={phone}
            name="phone"
            className={formControlClasses}
            placeholder="Телефон*"
            onBlur={(e) => blurHandler(e)}
            onChange={(e) => phoneHandler(e)}
          ></input>
          {phoneDirty && phoneError && (
            <div className={controlErrorClasses}>Укажите корректный номер</div>
          )}
        </div>

        <div className="justify-self-auto">
          <div
            className="w-[200px] mt-2 md:mt-0"
            onClick={() => buttonClicked()}
          >
            <DGSButton filled={true} disabled={false}>
              Оставить заявку
            </DGSButton>
          </div>
        </div>
      </form>
    </>
  );
};
