import {
  Route,
  Link,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  // Navigate,
} from "react-router-dom";

import { Main } from "./pages/Main";
import { Services } from "./pages/Services";
import { Error404 } from "./pages/Error404";
import { HardwareMaintenance } from "./pages/servicePages/HardwareMaintenance/HardwareMaintenance";
import { Layout } from "./components/Layout";
import { LayoutServices } from "./components/LayoutServices";
import { UserSupport } from "./pages/servicePages/UserSupport/UserSupport";
import { KnowledgeBase } from "./pages/servicePages/КnowledgeBase/KnowledgeBase";
import { Monitoring } from "./pages/servicePages/Monitoring/Monitoring";
import { ConsultingAndAudit } from "./pages/servicePages/ConsultingAndAudit/ConsultingAndAudit";
import { Outsourcing } from "./pages/servicePages/Outsourcing/Outsourcing";
import { NewServices } from "./pages/servicePages/NewServices/NewServices";
import { CloudMigration } from "./pages/servicePages/CloudMigration/CloudMigration";
import { ServicesUpdate } from "./pages/servicePages/ServicesUpdate/ServicesUpdate";
import { DataBackup } from "./pages/servicePages/DataBackup/DataBackup";
import { Policy } from "./pages/Policy";
import { LayoutMaintenance } from "./components/LayoutMaintenance";
import { AboutUs } from "./pages/aboutUs/AboutUs";
import { Vacancies } from "./pages/vacancies/Vacancies";
import { PartnerProgram } from "./pages/partnerProgram/PartnerProgram";
import { Bpm } from "./pages/bpm/Bpm";
import { Support1s } from "./pages/support1s/Support1s";

function App() {
  window.scroll(0, 0);
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={<Layout />}
        handle={{
          crumb: () => <Link to="/">Главная</Link>,
        }}
      >
        <Route index element={<Main />} />
        <Route
          path="services"
          element={<LayoutServices />}
          handle={{
            crumb: () => <Link to="/services">Услуги</Link>,
          }}
        >
          <Route index element={<Services />} />
          <Route
            path="hardware-maintenance"
            element={<HardwareMaintenance />}
            handle={{
              crumb: () => (
                <Link to="/services/hardware-maintenance">
                  Обслуживание IT инфраструктуры
                </Link>
              ),
            }}
          />
          <Route
            path="user-support"
            element={<UserSupport />}
            handle={{
              crumb: () => (
                <Link to="/services/user-support">Поддержка пользователей</Link>
              ),
            }}
          />
          <Route
            path="knowledge-base"
            element={<KnowledgeBase />}
            handle={{
              crumb: () => (
                <Link to="/services/knowledge-base">База знаний</Link>
              ),
            }}
          />
          <Route
            path="monitoring"
            element={<Monitoring />}
            handle={{
              crumb: () => <Link to="/services/monitoring">Монироринг</Link>,
            }}
          />
          <Route
            path="consulting-and-audit"
            element={<ConsultingAndAudit />}
            handle={{
              crumb: () => (
                <Link to="/services/consulting-and-audit">
                  IT консалтинг и аудит
                </Link>
              ),
            }}
          />
          <Route
            path="outsourcing"
            element={<Outsourcing />}
            handle={{
              crumb: () => (
                <Link to="/services/outsourcing">IT аутсорсинг</Link>
              ),
            }}
          />
          <Route
            path="new-services"
            element={<NewServices />}
            handle={{
              crumb: () => (
                <Link to="/services/new-services">
                  ИТ инфраструктура с нуля
                </Link>
              ),
            }}
          />
          <Route
            path="cloud-migration"
            element={<CloudMigration />}
            handle={{
              crumb: () => (
                <Link to="/services/cloud-migration">Миграция в облако</Link>
              ),
            }}
          />
          <Route
            path="services-update"
            element={<ServicesUpdate />}
            handle={{
              crumb: () => (
                <Link to="/services/services-update">
                  Модернизация инфраструктуры
                </Link>
              ),
            }}
          />
          <Route
            path="data-backup"
            element={<DataBackup />}
            handle={{
              crumb: () => (
                <Link to="/services/data-backup">Резервное копирование</Link>
              ),
            }}
          />
        </Route>
        {/* IT поддержка */}
        <Route
          path="it-maintenance"
          element={<LayoutMaintenance />}
          handle={{
            crumb: () => <Link to="/it-maintenance">IT Обслуживание</Link>,
          }}
        >
          <Route index element={<UserSupport />} />
          <Route
            path="user-support"
            // element={<Navigate to="/services/user-support" replace />}
            element={<UserSupport />}
            handle={{
              crumb: () => (
                <Link to="/it-maintenance/user-support">
                  Поддержка пользователей
                </Link>
              ),
            }}
          />
          <Route
            path="hardware-maintenance"
            element={<HardwareMaintenance />}
            handle={{
              crumb: () => (
                <Link to="/it-maintenance/hardware-maintenance">
                  Обслуживание IT инфраструктуры
                </Link>
              ),
            }}
          />
          <Route
            path="data-backup"
            element={<DataBackup />}
            handle={{
              crumb: () => (
                <Link to="/it-maintenance/data-backup">
                  Резервное копирование
                </Link>
              ),
            }}
          />
        </Route>

        <Route
          path="bpm"
          element={<Bpm />}
          handle={{
            crumb: () => <Link to="/bpm">Автоматизация бизнес процессов</Link>,
          }}
        />
        <Route
          path="support-1s"
          element={<Support1s />}
          handle={{
            crumb: () => <Link to="/support-1s">Сопровождение 1С</Link>,
          }}
        />
        <Route
          path="about-us"
          element={<AboutUs />}
          handle={{
            crumb: () => <Link to="/about-us">О нас</Link>,
          }}
        />
        <Route
          path="partner-program"
          element={<PartnerProgram />}
          handle={{
            crumb: () => (
              <Link to="/partner-program">Партнерская программа</Link>
            ),
          }}
        />
        <Route
          path="vacancies"
          element={<Vacancies />}
          handle={{
            crumb: () => <Link to="/vacancies">Вакансии</Link>,
          }}
        />
        <Route
          path="policy"
          element={<Policy />}
          handle={{
            crumb: () => <Link to="/policy">Политика</Link>,
          }}
        />

        <Route
          path="*"
          element={<Error404 />}
          handle={{
            crumb: () => <Link to="404">Страница не найдена</Link>,
          }}
        />
      </Route>
    ),
    {
      future: {
        v7_relativeSplatPath: true,
        v7_fetcherPersist: true,
        v7_normalizeFormMethod: true,
        v7_partialHydration: true,
        v7_skipActionStatusRevalidation: true,
        v7_skipActionErrorRevalidation: true,
      },
    }
  );

  return (
    //
    <RouterProvider
      router={router}
      future={{
        v7_startTransition: true,
      }}
    />
  );
}
export default App;
