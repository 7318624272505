import React from "react";
import { AboutUsContent } from "./AboutUsContent";
import { FormContactUs } from "../../components/Forms/FormContactUs";
import { Wrapper } from "../../components/Wrapper";
import { PageHeader } from "../../components/PageHeader";

export const AboutUs = () => {
  window.scroll(0, 0);
  return (
    <div>
      <PageHeader pageName="О нас" />
      <Wrapper>
        <AboutUsContent />
        <FormContactUs />
      </Wrapper>
    </div>
  );
};
